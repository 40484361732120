import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div>
          Alloy Fund
        </div>
        <div style={{marginBottom: '50px', fontSize: '17px', width: '500px'}}>
          Investing in big ideas and breakthrough discoveries. 
        </div>
        <div style={{fontSize: 14, color: '#a5a5a5'}}>
          <strong>Investors:{" "}</strong> 
            <a style={{color: 'inherit'}} href="https://www.linkedin.com/in/faviero" rel="noreferrer" target="_blank">Bruno</a>{", "}
            <a style={{color: 'inherit'}} href="https://www.linkedin.com/in/maxkolysh/" rel="noreferrer" target="_blank">Max</a>{", "}
            <a style={{color: 'inherit'}} href="https://www.linkedin.com/in/unitarymatrix/" rel="noreferrer" target="_blank">Mei</a>
             <br/>
            <strong>Entrepreneur in Residence:{" "}</strong> 
            <a style={{color: 'inherit'}} href="https://www.linkedin.com/in/david-benhaim-a99b6742/" rel="noreferrer" target="_blank">David</a>
        </div>
        <div style={{fontSize: 14, color: '#a5a5a5'}}>
          <p style={{margin: 0}}>Reach out at hi@alloyfund.com</p>
        </div>
      </header>
    </div>
  );
}

export default App;
